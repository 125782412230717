import * as React from 'react'
import styled from 'styled-components'

// COMPONENTS
import { ShadowText } from 'components/styled/typography'

// ASSETS
import { SCREEN } from 'styles/screens'
import BlocksIcon from 'svgs/blocks_.svg'
import MallIcon from 'svgs/mall_.svg'
import TodoIcon from 'svgs/todo.svg'
import MicrophoneIcon from 'svgs/microphone.svg'
import Logo from 'svgs/logo_gray.svg'
import { FixedContainer } from '../styled/containers'
import { getTranslation } from '../../data/dictionary'

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 100px;
  min-height: 700px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`

const StyledFixedContainer = styled(FixedContainer)`
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
  }
`

const ValuesContainer = styled.div`
  flex: 1 1 65%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

const Value = styled.div`
  flex: 1 1 45%;
  margin-bottom: 36px;

  svg {
    max-width: 64px;
    max-height: 64px;
    margin-right: 12px;
  }
  > p {
    max-width: 430px;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    svg {
      max-width: 45px;
      max-height: 45px;
    }
    h6 > span {
      font-size: 19px;
    }
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;

  > h6 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
  }
`

const HeadingContainer = styled.div`
  flex: 1 0 15%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-end;
  padding-top: 160px;
  align-self: stretch;

  > svg {
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    display: none;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    order: -1;
    margin-bottom: 24px;
    flex: unset;
  }
`

const ValuesSection = () => (
  <Container>
    <StyledFixedContainer>
      <div>
      <ShadowText highlight>
          <h3>{getTranslation('the_values', 'The Values')}</h3>
        </ShadowText>
      <ValuesContainer>
        <Value>
          <Title>
            <BlocksIcon />
            <h6>{getTranslation('values_heading_1', 'Community-driven')}</h6>
          </Title>
          <p>
            {getTranslation(
              'values_description_1',
              'BuildUp is a community of people advancing interest in Entrepreneurship in Armenia.',
            )}
          </p>
        </Value>
        <Value>
          <Title>
            <MallIcon />
            <h6>{getTranslation('values_heading_2', 'Accelerate Industry')}</h6>
          </Title>
          <p>
            {getTranslation(
              'values_description_2',
              'Switching from an outsource-oriented economy to a product-oriented is kind of must for Armenia now. We believe in it.',
            )}
          </p>
        </Value>
        <Value>
          <Title>
            <TodoIcon />
            <h6>{getTranslation('values_heading_3', 'The Can-do Spirit')}</h6>
          </Title>
          <p>
            {getTranslation(
              'values_description_3',
              'We are focused on building a great community and professional team, so our students can focus only on growth.',
            )}
          </p>
        </Value>
        <Value>
          <Title>
            <MicrophoneIcon />
            <h6>
              {getTranslation(
                'values_heading_4',
                'The best-of-the-best attitude',
              )}
            </h6>
          </Title>
          <p>
            {getTranslation(
              'values_description_4',
              "We are in love with effectiveness. That's why we have a curatorial approach to our course content with expert deans.",
            )}
          </p>
        </Value>
      </ValuesContainer>
      </div>
   
      <HeadingContainer>
        
        <Logo />
      </HeadingContainer>
    </StyledFixedContainer>
  </Container>
)

export default ValuesSection
