import { keyframes } from 'styled-components'

export const load = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  90% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    display: none;
  }
`

export const fadeInText1 = keyframes`
  0% {
    opacity: 0;
    display: none;
    transform: scale(0)
  }
  40% {
    opacity: 0;
    transform: translate(0, 200%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }`

export const fadeInText2 = keyframes`
  0% {
    opacity: 0;
    display: none;
    transform: scale(0)
  }
  40% {
    opacity: 0;
    transform: translate(0, 150%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
`

export const fadeInText3 = keyframes`
  0% {
    opacity: 0;
    display: none;
    transform: scale(0)
  }
  40% {
    opacity: 0;
    transform: translate(0, -150%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
`

export const fadeInText4 = keyframes`
  0% {
    opacity: 0;
    display: none;
    transform: scale(0),traslate(0,0)
  }
  40% {
    opacity: 0;
    transform: translate(0,-200%);
  }
  100% {
    opacity: 1;
    transform: translate(0,-24%);
  }`
